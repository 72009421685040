import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-lg-row" }
const _hoisted_2 = { class: "flex-lg-row-fluid" }
const _hoisted_3 = { class: "card card-flush mb-5" }
const _hoisted_4 = { class: "card-header pt-5" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "d-flex flex-wrap my-1" }
const _hoisted_7 = {
  key: 0,
  class: "card-body p-0"
}
const _hoisted_8 = {
  key: 1,
  class: "card-body pt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "filter")
          ]),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "actions")
          ])
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_EntityLoading)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _renderSlot(_ctx.$slots, "content")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}