import {defineStore} from "pinia";
import {useLoadingStore} from "@/store/loading";
import TaskService from "@/core/services/TaskService";
import MetricValueService from "@/core/services/MetricValueService";

export const useTaskStore = defineStore('taskStore', {
  state: () => ({
    page: <any>{
      data: <any>[],
      total: 0,
      totalPages: 0,
    } as any,
    pageSecond: <any>{
      data: <any>[],
      total: 0,
      totalPages: 0,
    }
  }),
  actions: {
    list(filter: any) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true);
      TaskService.findAll<any>(filter).then(res => {
        this.page = res;
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    },
    setTask(task) {
      const index = this.page.data.findIndex(item => {
        const {id} = item;
        return id === task.id;
      })
      this.page.data[index] = task
    },
    remove(id) {
      this.page.data = this.page.data.filter(item => item.id !== id);
    },
    listSecond(filter) {
      TaskService.findAll<any>(filter).then(res => {
        this.pageSecond = res
      })
    }
  }
})




